
import { defineComponent, ref } from 'vue'
import PageBlock from '@/components/layout/PageBlock.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import InfoCards from '@/components/shared/templates/InfoCards.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import type { BreadcrumbsLink } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    PageBlock,
    DetailsHero,
    TmFormLine,
    InfoCards,
    InfoCard,
  },
  setup() {
    const breadcrumbsLink = ref<BreadcrumbsLink[]>([
      {
        label: 'Contacts',
        name: 'base.contacts',
      },
      {
        label: 'Settings',
        name: 'base.contacts.settings',
      },
      {
        label: 'Opt-out settings',
      },
    ])

    const optOutConfirmation = ref('enabled')
    const optOutConfirmationSend = ref(true)
    const messageTemplate = ref('5 sent messages with status “Failed”')

    const autoUnsubscribe = ref('enabled')
    const unsubscribeAfterOptions = ref([
      '5 sent messages with status “Failed”',
      '10 sent messages with status “Failed”',
      '20 sent messages with status “Failed”',
    ])
    const unsubscribeAfterValue = unsubscribeAfterOptions.value[0]
    const smsChatSettings = ref('close')

    return {
      breadcrumbsLink,
      optOutConfirmation,
      optOutConfirmationSend,
      messageTemplate,
      autoUnsubscribe,
      unsubscribeAfterOptions,
      unsubscribeAfterValue,
      smsChatSettings,
    }
  },
})
